export default {
  computed: {
    contextMenu() {
      if (!this.current) return [];
      const tools = this.tools;
      const list = [
        [
          {
            key: 'ns',
            attrs: { to: { name: 'domainNs', params: { id: this.current.id } } },
          },
          {
            key: 'whois',
            attrs: { to: { name: 'domainWhois', params: { id: this.current.id } } },
          },
        ],
      ];
      if (this.tools && this.tools.delete) {
        list[0].push({
          key: 'remove',
          color: 'del',
          askSure: true,
          disabled: !this.tools.delete.isEnable(this.current),
          tool: this.tools.delete,
          //handler: this.current.status.code === 1 ? this.deleteFromBasket : null,
        });
      }
      return list;
    },
  },
};
